import * as React from "react"
import '../assets/sass/app.scss';
import Layout from '../components/global/Layout.js';
import { HeroMedia, Cta } from '../components/hiv-testing-guidelines/HeroContent.js'
import WhoShouldGetTested from "../components/hiv-testing-guidelines/WhoShouldGetTested";
import FooterCalloutBar from "../components/global/FooterCalloutBar.js";
import Header from '../components/global/Header.js';
import scrollToElement from '../components/global/ScrollToElement';

const HIVTestingGuidelines = (props) => {
  return (
    <>
      <Header page="hiv-testing-guidelines" scrollToElement={scrollToElement} />
      <Layout
        page="hiv-testing-guidelines"
        heroMedia={<HeroMedia />}
        CTA={Cta}
      >
        <WhoShouldGetTested />
        <FooterCalloutBar/>
      </Layout>
    </>
  )
}

export default HIVTestingGuidelines
