import React, { useRef } from "react";

// Hero Media

export const HeroMedia = () => {
  return (
    <>
      <img
        className="media bkg d-none d-sm-block" 
        src='/images/hiv-testing-guidelines/testing-guidelines-hero.png'
        alt="Group of friends sitting outside on porch"
      />
      <img
        className="media bkg d-block d-sm-none"
        src='/images/hiv-testing-guidelines/testing-guidelines-hero-mobile.png'
        alt="Group of friends sitting outside on porch"
      />
    </>
  )
}

// Hero Call To Action

export const Cta = (props) => {
  return (
    <>
      <h1>
        HIV Testing Is for Everyone
      </h1>
      <p>
        The Centers for Disease Control and Prevention (CDC) recommends that everyone between the ages of 13 and 64 get tested for HIV at least once as part of routine healthcare.
      </p>
      <p className="hiv-testing-guidelines">
        Have pride in knowing your status. Learn more below about how often you should seek testing. 
      </p>
      <div
        onClick={() => {props.scrollToElement("nav-anchor-who-should-get-tested",30,120)}}
        className="scroll" role="button">
        <p className="copy">
          SCROLL
        </p>
        <img className="icon-scroll" src="/images/global/play-icon.svg" />
      </div>
    </>
  )
} 
