import * as React from "react";
import Bar from '../global/Bar.js'
import Tiles from './Tiles/Tiles.js'


export const WhoShouldGetTested = () => {
  return (
    <section className="container-fluid who-should-get-tested bkg-white">
      <div id="nav-anchor-who-should-get-tested" className="nav-anchor"/>
      <div className="container">
        <div className="row">
          <div className="col px-0">
            <h2>
              Who Should Get Tested for HIV and When
            </h2>
            <p className="summary">
              Anyone can get HIV—no matter your assigned sex at birth, gender 
              identity, or sexual orientation. That's why it's 
              important to know when you should get tested.
            </p>
            <p className="description">
              Click a card to learn more.
            </p>
            <Tiles />
            <p className="disclaimer">
            These recommendations are based on guidelines from the 
            CDC (Centers for Disease Control and Prevention).
            </p>
            
            <Bar classes="purple space-top" />
          </div>
        </div>
      </div>

    </section>
  )
}

export default WhoShouldGetTested;