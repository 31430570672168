import * as React from "react";

const Content = [
  // Card 1
  {
    demographic: <p>Ages 13 to 64</p>,
    explanation: (
      <p>
        The Centers for Disease Control and Prevention (CDC) recommends that everyone 
        between the ages of 13 and 64 get tested for HIV at least once as part of routine healthcare.
      </p>
    ),
    icon: "/images/hiv-testing-guidelines/couple_icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/couple_icon-mobile.svg",
    className: "couple",
    footnoteSymbol: "*",
  },
  // Card 2
  {
    demographic: <p>Monogamous people who are sexually active</p>,
    explanation: (
      <p>
        People who are sexually active should get tested for HIV at least once
        in their lifetime, even if they are in a monogamous relationship. It's
        important to know for sure if you or your partner is living with HIV.
      </p>
    ),
    icon: "/images/hiv-testing-guidelines/person-checked-icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/person-checked-icon-mobile.svg",
    className: "person-checked",
    footnoteSymbol: "*",
  },
  // Card 3
  {
    demographic: <p>Men who have sex with men (MSM)</p>,
    explanation: (
      <>
        <p>
          It's recommended that sexually active men who have sex with men (MSM)
          get tested more than once a year (for example, every 3 to 6 months).
        </p>
        <p>
          This group accounted for nearly 70% of new HIV diagnoses in the US.*
        </p>
        <p className="footnote">*According to 2019 data.</p>
      </>
    ),
    icon: "/images/hiv-testing-guidelines/Schedule_icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/Schedule_icon-mobile.svg",
    className: "scheduled",
    footnoteSymbol: <sup>†</sup>,
  },
  // Card 4
  {
    demographic: <p>People with more than 1 sexual partner</p>,
    explanation: (
      <p>
        People with more than one sexual partner should get tested for HIV at
        least once a year. Sharing your HIV status and sexual history, as well
        as getting tested for HIV together, can help make sexual experiences
        safer.
      </p>
    ),
    icon: "/images/hiv-testing-guidelines/Polyamory_icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/Polyamory_icon-mobile.svg",
    className: "social-group",
    footnoteSymbol: "*",
  },
  // Card 5
  {
    demographic: <p>Transgender people</p>,
    explanation: (
      <>
        <p>
          Transgender people should get tested for HIV at least once a year.
        </p>
        <p>
          Among transgender women interviewed in seven US cities, 42% were living with HIV.*
        </p>
        <p className="footnote">
          *According to a 2019-2020 study.
        </p>
      </>
    ),
    icon: "/images/hiv-testing-guidelines/transgender-icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/transgender-icon.svg",
    className: "transgender",
    footnoteSymbol: <sup>†</sup>,
  },
  // Card 6
  {
    demographic: (
      <p>People who have recently had an STD (sexually transmitted disease)</p>
    ),
    explanation: (
      <p>
        People should get tested for HIV as soon as possible after being
        diagnosed with or treated for an STD (sexually transmitted disease).
        Having another STD can make you more likely to acquire or transmit HIV.
      </p>
    ),
    icon: "/images/hiv-testing-guidelines/shield-icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/shield-icon-mobile.svg",
    className: "shield",
    footnoteSymbol: "*",
  },
  // Card 7
  {
    demographic: <p>People who use drugs with needles</p>,
    explanation: (
      <p>
        People who use illicit drugs with needles should get tested for HIV at
        least once a year.
      </p>
    ),
    icon: "/images/hiv-testing-guidelines/syringe-icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/syringe-icon-mobile.svg",
    className: "syringe",
    footnoteSymbol: "*",
  },
  // Card 8
  {
    demographic: <p>People who are or plan to get pregnant</p>,
    explanation: (
      <p>
        People who are pregnant or plan to become pregnant should get tested for
        HIV. Talk to a doctor if you are pregnant and living with HIV about ways
        to possibly protect your child from being born with HIV.
      </p>
    ),
    icon: "/images/hiv-testing-guidelines/pregnant-icon.svg",
    iconMobile: "/images/hiv-testing-guidelines/pregnant-icon-mobile.svg",
    className: "pregnant",
    footnoteSymbol: "*",
  },
];

export default Content;
