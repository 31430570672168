import React, { useState, useEffect } from 'react';
import Content from './Content';
import { CSSTransition } from "react-transition-group";
import { Interstitial } from '../../global/Interstitial';
import setInterstitialText from "../../global/SetInterstitialText.js";

const Tiles = () => {

  const [activeTile, updateactiveTile] = useState(undefined);

  useEffect(() => {
    const interstitialLinks = document.querySelectorAll('.opens-interstitial');
    const interstitial = document.querySelector('.interstitial-container.generic');
    const body = document.querySelector('body');
    interstitialLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        let url = e.target.href;
        const interstitialType = e.target.getAttribute('data-interstitial-type') || 'aidsvu';
        setInterstitialText(interstitialType);
        interstitial.classList.remove('interstitial-hidden');
        body.classList.add('hide-scroll');
        let interstitialContinue = interstitial.querySelector('.interstitial-link.continue');
        interstitialContinue.href = url;
      })
    });
  }, [activeTile]);


  const openTile = (newIndex) => {
    if (activeTile === newIndex) return;
    updateactiveTile(newIndex);
  }

  const closeTiles = () => {
    updateactiveTile(undefined);
  }

  useEffect(() => {
  }, [activeTile])

  return (
    <div className="tiles">
      {Content.map((card, index) =>
        <div
          className={`
              tile 
              bkg-dark-gold 
              index-${index}
            `}
          key={index}
          role="button"
          onClick={() => openTile(index)}
        >
          <div className="content ">
            <div className="number">
              {index + 1}.
            </div>
            <div className="demographic">
              {card.demographic}
            </div>
            <img src='/images/hiv-testing-guidelines/icon-expand.svg' className="icon-expand" alt="expand icon" />
          </div>
        </div>
      )}
      <CSSTransition
        in={activeTile >= 0}
        timeout={0}
        unmountOnExit
      >
        <div className={`
           explanation bkg-dark-gold
      `}>
          {Content[activeTile] &&
            <>
              <img className={`icon-tile desktop ${Content[activeTile].className}`} src={Content[activeTile].icon} />
              <img className={`icon-tile mobile ${Content[activeTile].className}`} src={Content[activeTile].iconMobile} />
              <div className="copy">
                {Content[activeTile].explanation}
              </div>
              <a 
                target="_blank"
                className="button white font-avenir-heavy opens-interstitial find-testing" 
                data-interstitial-type="aidsvu"
                href="https://aidsvu.org/services/#/testing"
              >
                FIND HIV TESTING NEAR YOU
              </a>
              <a 
                target="_blank"
                className="button white font-avenir-heavy order-at-home-tests opens-interstitial" 
                data-interstitial-type="gilead"
                href="https://together.takemehome.org/?utm_source=Gilead&utm_medium=Partner-Organic-English&utm_campaign=selftesting12_PRIORITY&utm_term=BHOC-no-campaign-term&utm_content=Partner-promotion"
              >
                ORDER AT-HOME TESTS{Content[activeTile].footnoteSymbol}
              </a>
              <p className="footnote">
                {Content[activeTile].footnoteSymbol}Eligibility requirements may apply.
              </p>
            </>
          }
          <img
            src="/images/global/close-icon-white.svg"
            className="icon-close"
            alt="close icon"
            onClick={() => closeTiles()}
            role="button"
          />
        </div>
      </CSSTransition>
      <Interstitial />
    </div>
  )
}

export default Tiles;